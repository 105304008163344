export const redirectUrls = {
    companyWebBaseUrl: window.COMPANYWEB_BASE_URL,
    pensionBaseUrl: window.PENSION_BASE_URL,
    groupBaseUrl: window.GROUP_BASE_URL,
    benefitsBaseUrl: window.BENEFITS_BASE_URL,
    allbenBaseUrl: window.ALLBEN_BASE_URL,
    benefitsAllbenBaseUrl: window.BENEFITS_ALLBEN_BASE_URL,
    benefitsStudioBaseUrl: window.BENEFITS_ALLBEN_STUDIO_BASE_URL,
    allbenStudioBaseUrl: window.ALLBEN_STUDIO_BASE_URL,
    wealthBaseUrl: window.WEALTH_BASE_URL,
    payrollBaseUrl: window.PAYROLL_BASE_URL,
    informationCollectionBaseUrl: window.INFORMATION_COLLECTION_BASE_URL,
    myCasesBaseUrl: window.MYCASES_BASE_URL,
    soderbergPartnersBaseUrl: window.SODERBERG_PARTNERS_BASE_URL,
    privateInsuracesBaseUrl: window.PRIVATE_INSURANCES_BASE_URL,
    mortgagesBaseUrl: window.MORTGAGES_BASE_URL,
};

export const matomoSiteProperties = {
    siteId: window.MATOMO_SITE_ID,
    baseUrl: window.MATOMO_BASE_URL,
};

export const userSnapProperties = {
  globalApiKey: window.USERSNAP_GLOBAL_API_KEY,
};
