import { useContext } from "react";
import { SecurityToolsContext } from "@sop/unify-ui-react";

const FUNCTION_NAMES = {
  HOMEPAGE: "Homepage",
  BENEFITS_CUSTOMER: "BenefitsCustomer",
  BENEFITS_ALLBEN_CUSTOMER: "BenefitsAllbenUsers",
  ALLBEN_CUSTOMER: "AllbenUsers",
  ALLBEN_STUDIO_CUSTOMER: "AllbenStudioUser",
  BENEFITS_STUDIO_CUSTOMER: "BenefitsStudioUser",
  PENSION_CUSTOMER: "PensionCustomer",
  PENSION_NONADVISORY_CUSTOMER: "PensionNonAdvisoryCustomer",
  WEALTH_CUSTOMER: "WealthCustomer",
  GROUP_CUSTOMER: "GroupCustomer",
  PAYROLL_CUSTOMER: "PayrollCustomer",
  COMPANYWEB_USERS: "CompanyWebUsers",
  PENSION_ADVISORY_CUSTOMER: "PensionAdvisoryCustomer",
  PRIVATE_INSURANCE_CUSTOMER: "PrivateInsurancesCustomer",
  SP_PLUS_CUSTOMER: "SpPlusCustomer",
};

export const usePermissions = () => {
  const security = useContext(SecurityToolsContext);
  return {
    showHomePage: security.canExecute(FUNCTION_NAMES.HOMEPAGE),
    isPensionCustomer: security.canExecute(FUNCTION_NAMES.PENSION_CUSTOMER),
    isPensionAdvisoryCustomer: security.canExecute(
      FUNCTION_NAMES.PENSION_ADVISORY_CUSTOMER
    ),
    isPensionNonAdvisoryCustomer: security.canExecute(
      FUNCTION_NAMES.PENSION_NONADVISORY_CUSTOMER
    ),
    isBenefitsCustomer: security.canExecute(FUNCTION_NAMES.BENEFITS_CUSTOMER),
    isAllbenCustomer: security.canExecute(FUNCTION_NAMES.ALLBEN_CUSTOMER),
    isBenefitsAllbenCustomer: security.canExecute(FUNCTION_NAMES.BENEFITS_ALLBEN_CUSTOMER),
    isAllbenStudioCustomer: security.canExecute(FUNCTION_NAMES.ALLBEN_STUDIO_CUSTOMER),
    isBenefitsStudioCustomer: security.canExecute(FUNCTION_NAMES.BENEFITS_STUDIO_CUSTOMER),
    isWealthCustomer: security.canExecute(FUNCTION_NAMES.WEALTH_CUSTOMER),
    isPayrollCustomer: security.canExecute(FUNCTION_NAMES.PAYROLL_CUSTOMER),
    isGroupCustomer: security.canExecute(FUNCTION_NAMES.GROUP_CUSTOMER),
    isCompanyWebUsers: security.canExecute(FUNCTION_NAMES.COMPANYWEB_USERS),
    isPrivateInsuranceUsers: security.canExecute(
      FUNCTION_NAMES.PRIVATE_INSURANCE_CUSTOMER
    ),
    isSpPlusCustomer: security.canExecute(FUNCTION_NAMES.SP_PLUS_CUSTOMER),
  };
};
